<template>
  <!-- MODAL CLOSING CALENDAR -->
  <b-modal
    id="closing-calendar-modal"
    ref="closing-calendar-modal"
    :title="$t('broadcastSideBar.closingCalendar')"
    hide-footer
    @hidden="clearBroadcastingSettingsModal"
  >
    <div class="d-flex w-100">
      <b-calendar
        v-model="calendarValue"
        :date-info-fn="dateClass"
        :locale="isLocale"
        :label-today="$t('datepicker.today')"
        :label-no-date-selected="$t('datepicker.noDateSelected')"
        :label-close-button="$t('table.close')"
        :label-help="$t('datepicker.help')"
        :label-current-month="$t('datepicker.currentMonth')"
        :label-next-month="$t('datepicker.nextMonth')"
        :label-next-year="$t('datepicker.nextYear')"
        :label-prev-month="$t('datepicker.prevMonth')"
        :label-prev-year="$t('datepicker.prevYear')"
        :label-today-button="$t('datepicker.todayButton')"
        :start-weekday="startWeekdayIndex"
        :initial-date="startWeekDay"
        :readonly="selectedCalendarMode === 'B'"
        :disabled="isModalBusy"
        @context="contextCalendarEvent"
      ></b-calendar>
      <div class="flex-fill pl-3">
        <b-form-group v-slot="{ ariaDescribedbyCalendar }" :label="$t('table.selectDayMode')">
          <b-form-radio
            v-model="selectedCalendarMode"
            :aria-describedby="ariaDescribedbyCalendar"
            name="closing-calendar-radios"
            value="A"
            :disabled="isModalBusy"
            @input="clearSelectedCalendarDates"
          >
            {{ $t('table.single') }}
          </b-form-radio>
          <b-form-radio
            v-model="selectedCalendarMode"
            :aria-describedby="ariaDescribedbyCalendar"
            name="closing-calendar-radios"
            value="B"
            :disabled="isModalBusy"
            @input="clearSelectedCalendarDates"
          >
            {{ $t('table.range') }}
          </b-form-radio>
        </b-form-group>
        <b-form-group :label="$t('channelModal.from')">
          <datepicker-wrapper
            v-model="closingFrom"
            :date-info-fn="dateClass"
            required
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            :disabled="selectedCalendarMode === 'A' || isModalBusy"
            :initial-date="initDate"
            @input="setDateRange"
          />
        </b-form-group>
        <b-form-group :label="$t('channelModal.to')">
          <datepicker-wrapper
            v-model="closingTo"
            required
            :date-info-fn="dateClass"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            :min="dayAfterClosingFrom"
            :disabled="selectedCalendarMode === 'A' || isModalBusy"
            :initial-date="initDate"
            @input="setDateRange"
          />
        </b-form-group>
        <div class="grid-col-2 gap-2">
          <b-dropdown
            split
            variant="outline-primary"
            :text="$t('table.openVerb')"
            :disabled="(!calendarValue && selectedRange.length === 0) || isModalBusy"
            @click="closingCalendarAction('open', 3)"
          >
            <b-dropdown-header>{{ $t('broadcast.openDay') }}:</b-dropdown-header>
            <b-dropdown-item @click="closingCalendarAction('open', 1)">{{ $t('sideBar.agencies') }}</b-dropdown-item>
            <b-dropdown-item @click="closingCalendarAction('open', 2)">{{ $t('sideBar.salehouses') }}</b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            split
            variant="primary"
            :text="$t('table.close')"
            :disabled="(!calendarValue && selectedRange.length === 0) || isModalBusy"
            @click="closingCalendarAction('close', 3)"
          >
            <b-dropdown-header>{{ $t('broadcast.closeDay') }}:</b-dropdown-header>
            <b-dropdown-item @click="closingCalendarAction('close', 1)">{{ $t('sideBar.agencies') }}</b-dropdown-item>
            <b-dropdown-item @click="closingCalendarAction('close', 2)">{{ $t('sideBar.salehouses') }}</b-dropdown-item>
          </b-dropdown>

          <b-dropdown
            split
            variant="outline-primary"
            :text="$t('table.allow')"
            :disabled="(!calendarValue && selectedRange.length === 0) || isModalBusy"
            @click="disAllowEjectionCalendarAction('allow', 3)"
          >
            <b-dropdown-header>{{ $t('table.allowEjection') }}:</b-dropdown-header>
            <b-dropdown-item @click="disAllowEjectionCalendarAction('allow', 1)">{{ $t('table.automaticGenitive') }}</b-dropdown-item>
            <b-dropdown-item @click="disAllowEjectionCalendarAction('allow', 2)">{{ $t('table.lowPriorityGenitive') }}</b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            split
            variant="primary"
            :text="$t('table.deny')"
            :disabled="(!calendarValue && selectedRange.length === 0) || isModalBusy"
            @click="disAllowEjectionCalendarAction('deny', 3)"
          >
            <b-dropdown-header>{{ $t('table.disallowEjection') }}:</b-dropdown-header>
            <b-dropdown-item @click="disAllowEjectionCalendarAction('deny', 1)">{{ $t('table.automaticGenitive') }}</b-dropdown-item>
            <b-dropdown-item @click="disAllowEjectionCalendarAction('deny', 2)">{{ $t('table.lowPriorityGenitive') }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import { BCalendar, BDropdown, BDropdownItem, BDropdownHeader } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import errorsHandler from '@/utils/errorsHandler';

export default {
  components: {
    DatepickerWrapper,
    BCalendar,
    BDropdown,
    BDropdownItem,
    BDropdownHeader,
  },
  props: {
    channel: {
      required: true,
      type: Object,
    },
    startWeekDay: { type: String, default: undefined },
  },
  // defineEmits<{
  //   (e: 'closeEvent'): void,
  // }>()
  data() {
    return {
      calendarValue: '',
      lastActiveYMD: '',
      selectedCalendarMode: 'A',
      selectedRange: [],
      closingFrom: '',
      closingTo: '',
      isModalBusy: false,
      startWeekdayIndex: process.env.VUE_APP_START_WEEK_DAY ? +process.env.VUE_APP_START_WEEK_DAY : 1,
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      broadcastingSettings: 'getBroadcastingSettings',
    }),
    dayAfterClosingFrom() {
      if (this.closingFrom) {
        let result = new Date(this.closingFrom);
        result.setDate(result.getDate() + 1);
        return result;
      }
    },
    initDate() {
      if (this.lastActiveYMD) return this.lastActiveYMD + '-01';
    },
  },
  destroyed() {
    this.$store.commit('clearBroadcastingSettings');
  },
  methods: {
    dateClass(ymd, date) {
      const classStr = [];
      const day = this.broadcastingSettings.find((e) => e.date === ymd);
      if (day) {
        if ((day.is_closed & 1) === 1) classStr.push('day-closed-agency');
        if ((day.is_closed & 2) === 2) classStr.push('day-closed-saleshouse');
        if ((day.disallow_ejection & 1) === 1) classStr.push('disallow-ejection-automatic');
        if ((day.disallow_ejection & 2) === 2) classStr.push('disallow-ejection-low-priority');
      }

      if (this.selectedRange.includes(ymd) || this.calendarValue === ymd) classStr.push('table-danger');
      return classStr;
    },

    async loadBroadcastingSettings(date_with_first) {
      const firstDayOfMonth = new Date(date_with_first);
      const date_from = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() - 1, 21).toISOString().slice(0, 10);
      const date_to = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() + 1, 7).toISOString().slice(0, 10);
      await this.$store.dispatch('GET_BROADCASTING_SETTINGS', {
        channelId: this.channel?.id,
        date: {
          'filter[date_from]': date_from,
          'filter[date_to]': date_to,
        },
      });
    },

    async contextCalendarEvent(params) {
      if (params.activeYMD.slice(0, 7) !== this.lastActiveYMD) {
        // execute only if month or year changed
        this.lastActiveYMD = params.activeYMD.slice(0, 7);
        const date_with_first = params.activeYMD.slice(0, 7) + '-01';
        await this.loadBroadcastingSettings(date_with_first);
      }
    },

    clearBroadcastingSettingsModal() {
      this.lastActiveYMD = '';
      this.calendarValue = '';
      this.selectedCalendarMode = 'A';
      this.selectedRange = [];
      this.closingFrom = '';
      this.closingTo = '';
      this.$store.commit('clearBroadcastingSettings');
    },

    doUpdate() {
      const startOfCurrentWeek = new Date(this.startWeekDay).getTime();
      const WEEK_IN_MILLISECONDS = 604800000;
      const endOfCurrentWeek = startOfCurrentWeek + WEEK_IN_MILLISECONDS;
      if (this.selectedCalendarMode === 'A') {
        // for one day select
        const calendarDate = new Date(this.calendarValue).getTime();
        const diff = calendarDate - startOfCurrentWeek;
        if (diff < WEEK_IN_MILLISECONDS && diff >= 0) this.$emit('closeEvent');
      } else {
        // for range
        const calendarDateFrom = new Date(this.closingFrom).getTime();
        const calendarDateTo = new Date(this.closingTo).getTime();
        if (
          (calendarDateFrom <= startOfCurrentWeek && calendarDateTo >= startOfCurrentWeek) ||
          (calendarDateFrom >= startOfCurrentWeek && calendarDateFrom <= endOfCurrentWeek)
        )
          this.$emit('closeEvent');
      }
    },

    setDateRange() {
      this.selectedRange = [];
      if (this.closingFrom && this.closingTo) {
        let start = new Date(this.closingFrom);
        let end = new Date(this.closingTo);
        for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
          this.selectedRange.push(new Date(dt).toISOString().slice(0, 10));
        }
      } else if (this.closingFrom) this.selectedRange.push(this.closingFrom);
      else if (this.closingTo) this.selectedRange.push(this.closingTo);
    },

    clearSelectedCalendarDates() {
      this.calendarValue = '';
      this.selectedRange = [];
      this.closingFrom = '';
      this.closingTo = '';
    },

    async closingCalendarAction(type, param) {
      // param types, decimal (binary):
      // 0 (00) - open to all;
      // 1 (01) - closed for agencies;
      // 2 (10) - closed for sale houses;
      // 3 (11) - closed for all.
      this.isModalBusy = true;
      const apiName = type === 'open' ? 'PATCH_OPEN_DAYS' : 'PATCH_CLOSE_DAYS';

      const formData =
        this.selectedCalendarMode === 'A' ? [{ date: this.calendarValue, is_closed: param }] : this.selectedRange.map((d) => ({ date: d, is_closed: param })); //A:single, B:range
      await this.$store.dispatch(apiName, {
        channel_id: this.channel?.id,
        days: formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: param === 'open' ? this.$i18n.t('alert.openDay') : this.$i18n.t('alert.closeDay'),
          });

          this.doUpdate();
          const date_with_first = this.lastActiveYMD.slice(0, 7) + '-01';
          this.clearSelectedCalendarDates();
          this.loadBroadcastingSettings(date_with_first);
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },

    async disAllowEjectionCalendarAction(type, param) {
      // param types, decimal (binary):
      // 0 (00) - allow ejection for all
      // 1 (01) - disallow ejection for automatic
      // 2 (10) - disallow ejection for low priority
      // 3 (11) - disallow ejection for all.
      this.isModalBusy = true;
      const apiName = type === 'allow' ? 'PATCH_BROADCASTING_ALLOW_EJECTION' : 'PATCH_BROADCASTING_DISALLOW_EJECTION';

      const formData =
        this.selectedCalendarMode === 'A'
          ? [{ date: this.calendarValue, disallow_ejection: param }]
          : this.selectedRange.map((d) => ({ date: d, disallow_ejection: param })); //A:single, B:range
      await this.$store.dispatch(apiName, {
        channel_id: this.channel?.id,
        days: formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: param === 'allow' ? this.$i18n.t('alert.ejectionAllowed') : this.$i18n.t('alert.ejectionDenied'),
          });

          this.doUpdate();
          const date_with_first = this.lastActiveYMD.slice(0, 7) + '-01';
          this.clearSelectedCalendarDates();
          this.loadBroadcastingSettings(date_with_first);
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },
  },
};
</script>

<style lang="sass">
$shadow-day-closed-agency: inset 0px 19px 0px 0px hsla(50, 90%, 45%, 0.45)
$shadow-day-closed-saleshouse: inset 0px -19px 0px 0px hsla(345, 100%, 77%, 0.45)
$shadow-disallow-ejection-automatic: inset 0px 19px 0px 0px hsla(177, 70%, 54%, 0.45)
$shadow-disallow-ejection-low-priority: inset 0px -19px 0px 0px hsla(211, 100%, 62%, 0.45)

#closing-calendar-modal > .modal-dialog
  max-width: 600px

  .b-calendar-grid-body > .row
    div.col
      border: 2px solid hsl(222, 14%, 100%)
      position: relative
      &> span.btn
        z-index: 2
        position: relative
    div.col[class*=day-]:not(.table-danger)::after,
    div.col[class*=disallow-ejection-]:not(.table-danger)::before
      position: absolute
      display: block
      height: 100%
      width: 17px
      z-index: 0
      content: ' '

    div.col[class*=day-]::after
      top: 0
      right: 0
      bottom: 0
    div.col[class*=disallow-ejection-]::before
      top: 0
      left: 0
      bottom: 0

    div.col.day-closed-agency:not(.day-closed-saleshouse)::after
      box-shadow: $shadow-day-closed-agency
    div.col.day-closed-saleshouse:not(.day-closed-agency)::after
      box-shadow: $shadow-day-closed-saleshouse
    div.col.day-closed-agency.day-closed-saleshouse::after
      box-shadow: $shadow-day-closed-agency, $shadow-day-closed-saleshouse

    div.col.disallow-ejection-automatic:not(.disallow-ejection-low-priority)::before
      box-shadow: $shadow-disallow-ejection-automatic
    div.col.disallow-ejection-low-priority:not(.disallow-ejection-automatic)::before
      box-shadow: $shadow-disallow-ejection-low-priority
    div.col.disallow-ejection-automatic.disallow-ejection-low-priority::before
      box-shadow: $shadow-disallow-ejection-automatic, $shadow-disallow-ejection-low-priority

[data-color-scheme="dark"]
  #closing-calendar-modal
    .b-calendar-header .form-control
      background-color: initial
      color: inherit
      border-color: #3b414e
    .b-calendar-grid-body .row div.col
      border-color: #3b414e
      & > span.btn:hover
        background-color: #3b414e
</style>
